/* -------------********************************---------------- 
                          MAIN STYLING
 --------------- ********************************---------------- */

/* -------------********************************---------------- 
        Styling for responsiveness can be found at the end of the document 
-------------********************************----------------  */

.profile-page {
  display: flex;
  flex-direction: row;
  padding-top: 60px;
  padding-bottom: 0px;
  background: white;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
  justify-content: center;
  min-height: 55vh;
}

.content-profile {
  width: 100%;
  background: #f1f1f4;
  height: 100vh;
  overflow-y: scroll;
}

.products-part {
  width: 95%;
}

.main-bg {
  background-image: url("./../../Assets/Media/Backgrounds/popupbg.png");
  background-position-y: center;
  background-size: cover;
}

.ant-tabs-tab {
  color: #101c4c !important; /* Tab text color */
  transition: color 0.3s ease; /* Smooth transition for hover */
}

.ant-tabs-tab:hover {
  color: #101c4c !important; /* Hover text color */
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #101c4c !important; /* Active tab text color */
}

.ant-tabs-ink-bar {
  background-color: #101c4c !important; /* Bottom indicator color */
}

.ant-upload-drag-icon {
  color: #101c4c !important; /* Active tab text color */
}

.profile-inner-part {
  border-radius: 12px;
  margin-bottom: 40px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  box-shadow: 0px 0px 0px 1px #fbcb04;
  background: white;
}

.personal-info-heading {
  background: transparent;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: 1px solid #ffffffad;
}

.profile-part {
  width: 27%;
  margin-left: 10%;
  position: -webkit-sticky;
  position: sticky;
}

.title-sub1 {
  margin-left: 0px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: grey;
  text-transform: none;
  padding: 0px 5px;
  font-weight: 400;
  font-style: normal;
}

.basic-info1 {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  background: transparent;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.basic-info {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
}

.image-part {
  width: 100%;
  margin-bottom: 20px;
}

.info-part1 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.info-part {
  width: fit-content;
  box-shadow: 1px 1px 3px grey;
  border-left: 4px solid #101c4c;
  position: relative;
  overflow: hidden;
  width: 96%;
  margin: 2%;
  border-radius: 8px;
}

.profile-info-part {
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 1px 1px 2px grey;
  border-top: 4px solid #101c4c;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 96%;
  margin: 2%;
  border-radius: 8px;
}

.actual-address {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.profile-info-part img,
h1 {
  z-index: 1;
  position: relative;
}

.person-image {
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
}

.profile-person-image {
  width: 120px;
  height: 120px;
  margin: -80px 16px 0;
  padding: 4px;
  background: white;
  box-shadow: 0 0 3px grey;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}

.profile-person-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Make the image cover the entire container */
  border-radius: 8px;
  /* Ensure the image inherits the container's border-radius */
}

.profile-person-image-hover {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  transition: all 0.3s ease-in-out;
  justify-content: center;
  flex-direction: column;
  color: white;
  backdrop-filter: blur(5px);
  border-radius: 8px;
  z-index: 99;
  align-items: center;
  font-size: 24px;
}

.profile-person-image:hover .profile-person-image-hover {
  display: flex;
}

.profile-details-heading {
  padding: 12px 16px;
  border-bottom: 1px solid grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-table {
  margin-bottom: 0px;
}

.profile-table tr th {
  color: #99a1b7;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  padding: 12px 20px;
}

.profile-table tr td {
  color: #002549;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  padding: 22px 20px;
}

.profile-table tr:first-child th,
.profile-table tr:first-child td {
  padding-bottom: 0px;
}

.profile-table tr:not(last-child) th,
.profile-table tr:not(last-child) td {
  padding-bottom: 0px;
}

.profile-table tr:last-child th,
.profile-table tr:last-child td {
  padding-bottom: 22px;
}

.profile-name {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
  color: black;
  text-transform: none;
  padding: 0px 5px;
  font-weight: 400;
  font-style: normal;
  margin: 30px 0px 20px;
  margin: 8px 0px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.dashboard-profile-person-explanation {
  color: #99a1b7;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  display: flex;
  gap: 12px;
}

.dashboard-profile-person-explanation span {
  border-right: 1px solid #99a1b7;
  padding: 0px 8px 0px 0px;
}

.dashboard-profile-person-explanation span:last-child {
  border-right: 0px solid grey;
}

.user-product-image {
  height: 180px;
  width: 300px;
  margin-left: 40px;
  margin-bottom: 15px;
  border-radius: 8px;
}

li {
  list-style: none;
}

/* .MuiBox-root {
  height: 100% !important;
} */

.tab-content-custom {
  width: 80%;
}

.domains-part {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0%;
  justify-content: center;
  margin-bottom: 40px;
}

.domain-part {
  background: linear-gradient(45deg, #002549, transparent);
  padding: 20px;
  color: white;
  font-family: "Open Sans", sans-serif;
  border-radius: 12px;
  position: relative;
  width: 45%;
  margin: 2% 1.5% 0%;
  box-shadow: 2px -2px 0px #fbcb04, 4px -4px 0px black;
  transition: all 0.3s ease-in-out;
  /* background: url(./Media/card-background.png); */
}

.domain-part:hover {
  box-shadow: -2px 2px 0px #fbcb04, -4px 4px 0px black;
}

.domains-list-view {
  padding: 0px 20px 20px;
  font-family: "Open Sans", sans-serif;
}

/* .domain-status {
  position: absolute;
  top: 5px;
  right: 5px;
  background: green;
  padding: 2px 8px;
  border-radius: 10px;
} */

.domain-status {
  position: absolute;
  border-bottom: 25px solid #4d9c40;
  border-left: 26px solid transparent;
  border-right: 28px solid transparent;
  height: 0;
  width: 100px;
  text-align: center;
  transform: rotate(45deg);
  right: -33px;
  top: 5px;
  color: #fff;
  font-size: 16px;
}

.domain-status::before {
  content: "";
  border-bottom: 11px solid #2d6824;
  border-left: 9px solid transparent;
  transform: rotate(45deg);
  left: -23px;
  top: 19px;
  position: absolute;
}

.domain-status:after {
  content: "";
  border-bottom: 11px solid #2d6824;
  border-left: 11px solid transparent;
  transform: rotate(45deg);
  left: 61px;
  top: 19px;
  position: absolute;
}

.domain-name-current {
  font-size: 38px;
  font-weight: 800;
  margin: 0px 0px 28px;
}

.back-btn-domain {
  background: #101c4c;
  color: white;
  border-radius: 8px;
  font-size: 34px;
  padding: 4px;
}

.MuiTabs-scroller {
  padding-top: 40px;
  background: #80808021;
  width: 200px;
}

.MuiButtonBase-root-MuiTab-root {
  display: inherit;
  text-align: left;
  align-items: self-start;
}

.MuiTab-root {
  display: inherit !important;
  text-align: left !important;
  align-items: flex-start !important;
  /* Change "self-start" to "flex-start" for correct alignment */
  text-transform: capitalize !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 600 !important;
  padding: 15px !important;
}

.MuiButtonBase-root-MuiTab-root.Mui-selected {
  background: grey !important;
  color: white !important;
}

.MuiTab-root {
  display: inherit !important;
  text-align: left;
  align-items: self-start;
}

.MuiTab-root.Mui-selected {
  background: #002549 !important;
  color: white !important;
  position: relative;
  border-right: none !important;
}

.MuiTab-root.Mui-selected::after {
  content: "";
  position: absolute;
  border-left: none;
  border-right: 10px solid #fbcb04;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  margin-left: 0;
  margin-top: -10px;
  top: 50%;
  left: auto;
  right: 0;
  z-index: 1;
}

.MuiTabs-root {
  overflow: unset !important;
  min-height: unset !important;
}

.MuiTabs-scrollbarSize {
  overflow-x: unset !important;
  overflow-y: unset !important;
}

.MuiTabs-hideScrollbar {
  overflow-x: unset !important;
  overflow-y: unset !important;
}

.MuiTabs-flexContainer {
  position: sticky;
  top: 80px;
}

.manage-btn {
  background: #fbcb04;
  padding: 4px 8px;
  border-radius: 8px;
  color: black;
  box-shadow: 0px 0px 0px 1px white;
  transition: all 0.3s ease-in-out;
}

.manage-btn:hover {
  transform: scale(1.02);
}

.domain-detail-div {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 8px;
  gap: 10px;
  border: 1px solid #80808040;
  background: white;
}

.domain-details-div {
  display: flex;
  gap: 30px;
  padding: 6px 20px 20px;
}

.domain-detail-div-inner {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.back-btn {
  background: #101c4c;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  border-radius: 8px;
  margin-top: 20px;
}

.domain-div-tabs {
  width: 100%;
  display: flex;
  gap: 12px;
  margin-top: 30px;
  margin-bottom: 10px;
  border-bottom: 1px solid #80808042;
  padding: 20px 20px 0px;
}

.domain-div-tabs li {
  font-size: 18px;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.nameservers-tab {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  padding: 0px 20px 8px;
}

.nameserver-tab {
  width: 49%;
  margin: 10px 0px;
  font-family: "Open Sans", sans-serif;
}

.name-server-input:focus {
  border: 1px solid #fbcb04 !important;
}

.update-btn {
  background: green;
  color: white;
  padding: 5px 10px;
  border-radius: 8px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  margin: 0px 20px 26px;
}

.nameserver-note {
  font-family: "Open Sans", sans-serif;
  margin: 15px 0px;
  font-size: 15px;
}

.nameserver-note-span {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.registrant-contact {
  font-family: "Open Sans", sans-serif;
  margin: 1%;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #80808045;
  padding: 0px;
  border-radius: 8px;
  width: 47%;
  background: white;
}

.registrant-contact h2 {
  line-height: 170%;
  display: flex;
  font-weight: bold;
  justify-content: center;
  border-bottom: 1px solid black;
  padding: 8px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.registrant-contact p {
  margin: 5px 0px;
  justify-content: space-between;
  border-bottom: 1px dashed #8080804a;
  padding-bottom: 10px;
  padding: 5px 15px;
  gap: 25%;
}

.registrant-contact p:last-child {
  border-bottom: 0px solid grey;
}

.registrant-contact p span {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  display: flex;
}

.dns-management-tab {
  font-family: "Open Sans", sans-serif;
  padding: 0px 20px 26px;
}

.m-sbold {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.dns-managment-inner-tab {
  font-family: "Open Sans", sans-serif;
  border-radius: 6px;
  padding: 4px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dns-mgmt-tabs-list {
  border-bottom: 0.5px solid #8080802e;
  width: 100%;
}

.dns-managment-inner-tab:hover {
  cursor: pointer;
}

.add-record-btn {
  color: white !important;
  padding: 9px 15px;
  border-radius: 6px;
  height: fit-content;
}

.dash-item {
  font-size: 18px;
  margin-top: 2px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.domain-detail-inner-span {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: grey;
}

.domains-view-part {
  width: 100%;
}

.view-buttons {
  margin-bottom: 20px;
  display: flex;
  gap: 25px;
  justify-content: space-between;
  border-bottom: 1px solid #8080803b;
  padding-bottom: 10px;
  margin-bottom: 18px;
  padding: 12px 24px;
  position: sticky;
  top: 66px;
  z-index: 2;
  background: #f6f6f6;
}

.view-buttons-main {
  gap: 15px;
  display: flex;
}

.purchased-domains {
  font-size: 25px;
  left: 2%;
  position: relative;
}

.view-button {
  font-size: 24px;
}

.active-view {
  padding: 5px;
  color: white;
  background-color: #101c4c;
  border-radius: 6px;
}

.list-part {
  display: flex;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  align-items: center;
  padding-bottom: 10px;
  padding: 10px;
  border: 1px solid #80808047;
  border-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.list-part:last-child(-1) {
  border-bottom: 0px solid #80808047;
}

.domains-list-view .list-views-domains-div div:last-child .list-part {
  border-bottom: 1px solid #80808047;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.list-part div:last-child {
  border-bottom: 1px solid #80808047;
}

/* .list-part:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.list-part:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
} */

.list-item-name {
  width: 40%;
}

.list-item-expiry {
  width: 26%;
}

.list-item-btn {
  width: 17%;
  text-align: right;
}

.list-item-status {
  width: 17%;
  text-align: center;
}

.list-item-status span {
  background-color: #5cd47e;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
}

.list-views-domains-div {
  width: 100%;
  position: relative;
  overflow: scroll;
  height: 76.5vh;
}

.list-view-domains-header {
  position: sticky;
  width: 100%;
  height: fit-content;
  top: 0px;
  left: 0px;
  display: flex;
  padding: 12px;
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 2;
  background: linear-gradient(90deg, #002549, #fbcb04);
  color: white;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.add-record-input {
  border-radius: 8px;
}

.add-record-input:focus {
  border: 1px solid #fbcb04 !important;
}

.records-table-dns {
  border-radius: 8px !important;
}

.loader-page {
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 24px;
  height: 50vh;
}

.dna-wrapper circle:nth-child(even) {
  fill: #fbcb04 !important;
}

.dna-wrapper circle:nth-child(odd) {
  fill: #101c4c !important;
}

.Profile-help-button {
  border: 1px solid white;
  box-shadow: 3px 4px black;
  border-radius: 10px;
  margin: 1rem;
  padding: 1rem;
}

.profile-info-hero {
  background: url(./../../Assets/Media/Backgrounds/Two4.png);
  height: 8rem;
}

.profile-info-content {
  padding: 12px 16px;
  text-align: left;
  display: flex;
}

.domain-details-hero {
  border-radius: 8px;
  box-shadow: 0px 0px 4px grey;
}

.domain-name-current-main {
  font-size: 45px;
  font-weight: 800;
  margin: 0px 0px 16px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #8080802e;
  /* background: url(./Media/card-background.png); */
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-size: cover;
  color: white;
}

.recommended-body {
  color: black;
}

.recommended-body tr:nth-child(odd) {
  background: white;
}

.recommended-body tr:nth-child(even) {
  background: #f6f6f6;
}

.domain-details {
  padding: 20px !important;
}

.MuiTableCell-root {
  font-family: "Open Sans", sans-serif !important;
}

.invoice-box {
  min-width: 700px !important;
  position: relative;
  overflow: hidden;
}

.invoice-box::before {
  position: absolute;
  left: -216px;
  top: -97px;
  width: 500px;
  height: 331px;
  background: #fbcb04;
  transform: rotate(133deg);
  content: "";
  z-index: 0;
}

.invoice-box::after {
  position: absolute;
  bottom: -111px;
  right: -207px;
  width: 500px;
  height: 331px;
  background: #fbcb04;
  transform: rotate(133deg);
  content: "";
  z-index: 0;
}

.settings-tabs .MuiTabs-scroller {
  padding-top: 0px !important;
  width: 190px !important;
}

.settings-heading {
  font-family: "Open Sans", sans-serif;
}

.knowledge-base-header {
  padding-top: 40px !important;
  background: url(./../../Assets/Media/Backgrounds/Two4.png);
}

.knowledge-article {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid rgba(128, 128, 128, 0.192);
  cursor: pointer;
  background: linear-gradient(45deg, #fbcb0414, transparent);
}

.knowledgebase-div {
  padding: 90px 40px 0px;
}

/* -------------********************************---------------- 
                  STYLING FOR RESPONSIVE SCREENS 
--------------- ********************************---------------- */
/* -------------********************************---------------- 
                    EXTRA LARGE SCREEN - Min 1910px 
--------------- ********************************---------------- */

@media only screen and (min-width: 1910px) {
  .profile-part {
    width: 30%;
  }

  .domain-part {
    width: 45%;
  }

  .knowledgebase-div {
    padding: 60px 22rem 0px;
  }
}

@media only screen and (max-width: 1280px) {
  .info-part1 {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* -------------********************************---------------- 
                   MOBILE SCREEN - Max - 767px 
--------------- ********************************---------------- */

@media only screen and (max-width: 767px) {
  .knowledgebase-div {
    padding: 60px 0px 0px;
  }
  .profile-details-heading {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  .profile-page {
    flex-direction: column;
    margin: 0px;
  }

  .profile-part {
    width: 90%;
    order: 1;
    margin-left: 5%;
  }

  .products-part {
    width: 100%;
    order: 2;
  }

  .MuiTabs-scroller {
    width: 100%;
  }

  .MuiTabs-scroller {
    padding-top: 0px;
  }

  .MuiTab-root {
    max-width: unset;
  }

  .tab-content-custom {
    width: 100%;
  }

  .info-part::after {
    left: -40px;
  }

  .domain-part {
    width: 95%;
  }

  /* .MuiBox-root {
    padding: 20px 0px !important;
  } */

  .profile-info-part {
    width: 100%;
  }

  .domain-name-current {
    font-size: 30px;
  }

  .list-part {
    flex-direction: column;
  }

  .list-item-name {
    width: 100%;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 22px;
  }

  .list-item-expiry {
    width: 60%;
  }

  .list-item-btn {
    width: 40%;
    text-align: right;
  }

  .list-item-status {
    width: 40%;
    text-align: right;
  }

  .list-part-mobile {
    font-family: "Open Sans", sans-serif;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 8px;
    padding: 15px 10px;
    border: 1px solid grey;
    border-radius: 0px;
    border-top: 0px;
  }

  .expiry-date-mobile {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }

  .domain-details {
    padding: 20px !important;
    margin-top: 60px;
  }

  .domain-name-current-main {
    font-size: 32px;
  }

  .domain-details-div {
    flex-direction: column;
  }

  .domain-detail-div {
    width: 100%;
  }

  .domain-detail-div-left {
    width: 30%;
    display: flex;
    justify-content: center;
  }

  .domain-detail-div-right {
    width: 70%;
  }

  .react-tabs__tab--selected {
    border: 1px solid grey;
  }

  .dns-management-tab {
    width: 100%;
    overflow: scroll;
  }

  .add-record-input {
    width: 200px;
  }

  .registrant-contact {
    width: 100%;
  }

  .profile-info-part {
    margin: 0px;
  }

  .content-profile {
    background: white;
  }

  .profile-page-div {
    margin-top: 60px;
    padding: 20px;
  }

  .info-part {
    margin: 0px;
    width: 100%;
    margin-top: 20px;
  }

  .dashboard-profile-person-explanation {
    flex-direction: column;
    margin-top: 18px;
    gap: 8px;
  }

  .dashboard-profile-person-explanation span {
    border: 0px;
  }

  .profile-table tr {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .profile-table tr td {
    padding-top: 4px;
  }

  .profile-table tr th {
    padding-top: 24px;
  }

  .profile-table tr:last-child th {
    padding-bottom: 0px;
  }

  .profile-table tr:last-child td {
    padding-top: 8px;
  }

  .profile-table th {
  }

  .domains-part {
    margin-top: 70px;
    padding: 16px;
    gap: 16px;
    overflow: hidden;
  }

  .view-buttons {
    flex-direction: column;
    top: 60px;
  }

  .view-buttons-main {
    justify-content: end;
  }

  .list-views-domains-div {
    top: 25px;
  }

  .domain-div-tabs .react-tabs__tab--selected {
    border-bottom: 3px solid #fbcb04 !important;
    background: #002549 !important;
  }

  .dns-management-tab .react-tabs__tab--selected {
    border-bottom: 3px solid #fbcb04 !important;
    background: #002549 !important;
  }
}
