/* navbar button hover effect css - border bottom from center  */

/* -------------********************************---------------- 
                          MAIN STYLING
 --------------- ********************************---------------- */

/* -------------********************************---------------- 
        Styling for responsiveness can be found at the end of the document 
-------------********************************----------------  */

.nav-hover {
  width: fit-content;
}

.top-nav-login-div {
  display: flex;
}

.nav-hover:after {
  display: block;
  content: "";
  padding: 0px 0px !important;
  border-bottom: solid 3px #fbcb04;
  transform: scaleX(0);
  transition: transform 300ms ease-in-out;
  position: relative;
  top: 11px;
}

.nav-hover:hover:after {
  transform: scaleX(1);
}

.nav-hover.fromright:after {
  transform-origin: 100% 50%;
}

.nav-hover.fromleft:after {
  transform-origin: 0 50%;
}

/* navbar button hover effect css - border bottom from center  - END*/

.mobile-menu-part {
  height: fit-content;
  background: white;
  margin-left: -10px;
  width: 110%;
  margin-top: 0px;
  font-family: "Open Sans", sans-serif;
}

.login-button {
  padding: 8px 20px;
}

.icon-colorr {
  color: white !important;
}

.icon-colorr:hover {
  cursor: pointer;
}

.top_nav {
  background: white;
  height: 15px;
}

.Navbar.black {
  color: black;
  height: 60px;
  padding: 0rem 4.65rem;
  width: 100%;
  box-shadow: 0 14px 28px rgb(0 0 0 / 0%), 0 10px 10px rgb(0 0 0 / 7%);
  backdrop-filter: blur(15px);
  background: #101c4c;
}

.Navbar.black .top-nav-part {
  background-color: #800000 !important;
  transition: 1s ease-in-out;
}

.topnav-icons {
  color: #800000;
  font-size: 22px;
  padding: 0px 0px;
  margin: 0px 6px;
  cursor: pointer;
}

.Navbar.black .top-nav-part .topnav-icons {
  font-size: 22px;
  color: gold;
  padding: 0px 0px;
  margin: 0px 6px;
}

.Navbar.black .top-nav-part .top-nav-text {
  color: white !important;
  transition: 2s;
}

.Navbar.black .nav-buttons {
  color: gold !important;
}

.Navbar.black .icon-colorr {
  color: #fbcb04 !important;
}

.Navbar.black .menu-icon:hover #menu-nav {
  background-color: black;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.brand-logo {
  font-family: Optima;
  font-size: 28px;
  font-weight: 700;
}

.brand-logo:hover {
  color: #fbcb04;
}

.nav-buttons {
  text-transform: uppercase;
  font-size: 18px !important;
  font-weight: 600 !important;
  font-style: normal;
  font-family: "Open Sans", sans-serif;
  letter-spacing: -0.03em;
  color: white !important;
  padding: 3px 0px;
  letter-spacing: 0.325px;
  margin-top: 7px;
}

.nav-buttons:hover {
  color: #fbcb04 !important;
}

.top-nav-flex {
  flex-direction: column;
}

/* ----------------------- CSS from Terces for Dropdown -- */

.dropdown-item {
  background-color: white;
  color: #fbcb04;
  display: flex;
}

.dropdown-item:hover {
  background-color: #fbcb04;
  color: black;
}

.drop-item {
  transform: scale(1) !important;
  transition: 0.5s ease-in-out;
  margin-top: 3px;
}

.drop-icon {
  color: gold;
  font-size: 26px;
}

.dropdown-icon:hover {
  background-color: black;
}

.dropdown-menu {
  background-color: transparent !important;
  border: none;
}

.Header-part {
  width: 100%;
}

.logo-container {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 12px;
}

.menu-container {
  background-color: white;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: black !important;
}

.mobile-loggedout-drawer {
  background: url(./../../Assets/Media/Backgrounds/Two4.png);
  background-size: cover;
  border: 1px solid white;
}

.menu-icon {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  height: 100%;
  align-items: center;
  display: flex;
}

.menu-icon:hover .line2 {
  width: 30px;
}

#menu-nav {
  display: none;
}

#domains {
  position: relative;
}

#company {
  position: relative;
}

#websuite {
  position: relative;
}

#logged-in {
  position: relative;
}

#logged-in::after {
  content: "";
  position: absolute;
  top: 40px;
  right: 0rem;
  border: 10px solid transparent;
  border-bottom: 10px solid #fbcb04;
}

#domains::after {
  content: "";
  position: absolute;
  top: 40px;
  right: 2rem;
  border: 10px solid transparent;
  border-bottom: 10px solid #fbcb04;
}

#company::after {
  content: "";
  position: absolute;
  top: 40px;
  right: 2rem;
  border: 10px solid transparent;
  border-bottom: 10px solid #fbcb04;
}

#websuite::after {
  content: "";
  position: absolute;
  top: 40px;
  right: 2rem;
  border: 10px solid transparent;
  border-bottom: 10px solid #fbcb04;
}

.menu-icon:hover #menu-nav {
  background-color: black;
  position: absolute;
  top: 86%;
  right: 7.725rem;
  width: 200px;
  display: grid;
  align-content: center;
}

.menu-icon:hover #menu-nav-domains {
  background-color: black;
  position: absolute;
  top: 100%;
  right: -10.5rem;
  width: 250px;
  display: grid;
  align-content: center;
  margin: 0px;
  padding: 0px;
}

.menu-icon:hover #menu-nav-unused {
  background-color: black;
  position: absolute;
  top: 86%;
  right: -2.875rem;
  width: 250px;
  display: grid;
  align-content: center;
}

.menu-icon:hover #logged-in-nav {
  background-color: black;
  position: absolute;
  top: 86%;
  right: 1.225rem;
  width: 200px;
  display: grid;
  align-content: center;
}

.menu-list a {
  color: black;
  font-size: 0.95rem;
  font-weight: 500;
  transition: 0.5s;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  width: 230px;
}

.menu-list .small {
  width: 200px !important;
}

.menu-list a:hover .drop-item {
  padding-left: 10px;
}

/* ------------ Dropdown CSS ended -------------  */

.person-icon {
  color: #fbcb04;
  margin-left: 6px;
  font-size: 20px;
}

.header-part {
  background: white;
  padding: 0rem 2rem 0rem 3rem;
}

.Navbar {
  width: 100%;
  position: fixed;
  background-color: none;
  left: 0;
  top: 0px;
  height: 60px;
  padding: 0rem 5rem;
  box-sizing: border-box;
  transition: 0.4s;
  z-index: 99;
  background: transparent;
}

.NavBackground {
  background: #101c4c;
}

.new-nav-box {
  display: flex;
  width: fit-content;
  background-color: white;
  border-radius: 12px;
}

.new-nav-item {
  width: 150px;
  padding: 25px 20px !important;
  border: 1px solid #fbcb04;
}

.new-nav-item:hover {
  background-color: #0e2454;
}

.new-nav-paragraph {
  font-family: "Open Sans", sans-serif;
  font-size: 0.7rem;
  font-weight: 100;
  line-height: 160%;
  text-transform: none !important;
  color: black;
}

.new-nav-heading {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize !important;
  margin: 15px 0px 10px !important;
  color: black;
}

.new-nav-item:hover .new-nav-heading {
  color: white;
}

.new-nav-item:hover .new-nav-paragraph {
  color: white;
}

.new-nav-box .new-nav-item:first-child:hover {
  border-bottom-left-radius: 11px;
  border-top-left-radius: 11px;
}

.new-nav-box .new-nav-item:last-child:hover {
  border-bottom-right-radius: 11px;
  border-top-right-radius: 11px;
}

.new-nav-item:first-child {
  border-bottom-left-radius: 11px;
  border-top-left-radius: 11px;
}

.new-nav-item:last-child {
  border-bottom-right-radius: 11px;
  border-top-right-radius: 11px;
}

.new-drop-icon-div {
  background-color: #1c0c74;
  border-radius: 46px;
  padding: 10px;
  width: fit-content;
}

.new-nav-item:hover .new-drop-icon-div {
  background-color: #fbcb04;
}

.new-nav-item:hover .drop-icon {
  color: #1c0c74;
}

.logged-in-item {
  min-width: 230px;
  width: fit-content;
  border: 1px solid #fbcb04;
}

.logged-in-item .logged-in-item-heading:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 15px;
  padding-top: 18px;
}

.logged-in-item .logged-in-item-heading:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 15px;
  padding-bottom: 16px;
}

.logged-in-item-heading {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize !important;
  padding: 10px 15px;
  padding-top: 15px;
  color: black;
}

.logged-in-item .logged-in-item-heading:hover {
  color: white;
  background-color: #0e2454;
}

.logged-in-item:first-child {
  border-bottom-left-radius: 11px;
  border-top-left-radius: 11px;
}

.logged-in-item:last-child {
  border-bottom-right-radius: 11px;
  border-top-right-radius: 11px;
}

.profile-icon {
  font-size: 28px;
  margin-right: 5px;
  margin-left: -5px;
}

.header-logo {
  /* margin-left: 40px; */
  margin-top: 10px;
}

.mobile-buttons {
  padding: 20px;
  box-shadow: 0px 0px 2px #80808073;
  border-radius: 12px;
}

.mobile-drawer {
  justify-content: space-between;
}

/* .mobile-drawer .MuiBox-root {
  height: fit-content !important;
} */

.mobile-drawer-top-box {
  background: url(./../../Assets/Media/Backgrounds/Two4.png);
  padding: 60px 20px 120px;
  background-size: cover;
  height: 30%;
  min-height: 315px;
}

.mobile-drawer-bottom-box {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: white;
  z-index: 9;
  height: 20% !important;
  min-height: 160px;
}

.mobile-drawer-links-box {
  margin-top: -140px;
}

.MuiTreeItem-label,
.MuiButton-root,
.MuiDialogTitle-root {
  font-family: "Open Sans", sans-serif !important;
}

.MuiTreeItem-content.Mui-selected {
  background: #fbcb04 !important;
  color: black;
}

.MuiTreeItem-label:hover {
  color: #101c4c;
}

/* -------------********************************---------------- 
                  STYLING FOR RESPONSIVE SCREENS 
--------------- ********************************---------------- */

/* -------------********************************---------------- 
                    EXTRA LARGE SCREEN - Min 1910px 
--------------- ********************************---------------- */

@media screen and (min-width: 1910px) {
  .Navbar.black {
    width: 100%;
    margin-left: 0;
    padding: 0px 22rem;
  }

  .Header-part {
    width: 70%;
  }

  .top-nav-part {
    padding: 10px 23rem 10px 23.2rem;
    left: 0px;
  }

  .Navbar {
    width: 100%;
    padding: 0px 22rem;
  }

  .menu-icon:hover #menu-nav {
    right: 7.5rem;
  }

  .menu-icon:hover #menu-nav-domains {
    right: -10.5rem;
  }

  .menu-icon:hover #menu-nav-unused {
    right: -2.795rem;
  }

  #websuite::after {
    right: 2rem;
  }

  #domains::after {
    right: 2rem;
  }

  #company::after {
    right: 2rem;
  }
}

/* -------------********************************---------------- 
                  SCREEN - Max - 900px 
--------------- ********************************---------------- */

@media only screen and (max-width: 900px) {
  .Navbar.black {
    padding: 0px 20px 0px 9px;
    top: 0px;
  }

  .Navbar {
    padding: 0px 20px 0px 6px;
    width: 100%;
    top: 0px;
  }
}

/* -------------********************************---------------- 
                   MOBILE SCREEN - Max - 767px 
--------------- ********************************---------------- */

@media only screen and (max-width: 767px) {
  .top-nav-part {
    display: none;
  }

  .header-logo {
    margin-left: 5px;
    margin-top: 10px;
  }

  .mobile-nav-items {
    padding: 20px !important;
    font-size: 18px;
    box-shadow: 0px 3px 7px 0px grey;
  }
}
