/* -------------********************************---------------- 
                          MAIN STYLING
 --------------- ********************************---------------- */

/* -------------********************************---------------- 
        Styling for responsiveness can be found at the end of the document 
-------------********************************----------------  */

#not-found {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 50vw;
  background-size: cover;
}
.not-found-title {
  display: block;
}
.not-found-title h2 {
  margin-bottom: 10px;
}
