@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75..100,300..800;1,75..100,300..800&display=swap");

body {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.popup-menuu {
  background-color: #ffffff;
  width: 48rem;
  position: fixed;
  transform: translate(-51%, -48%);
  left: 50%;

  top: 52%;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  display: none;
  text-align: center;
  z-index: 999;
  box-shadow: -0.0625rem 0 0.625rem 0 rgba(0, 0, 0, 0.15),
    0.3125rem 1.25rem 2.5rem 0 rgba(0, 0, 0, 0.15) !important;
}

@media only screen and (max-width: 767px) {
  .popup-menuu {
    width: 83%;
    position: fixed;
    left: 50%;
    top: 50%;
  }
}

.popup-menuu button {
  display: block;
  margin: 0 0 20px auto;
  background-color: rgba(0, 0, 0, 0);
  font-size: 30px;
  color: #c5c5c5;
  border: none;
  outline: none;
  cursor: pointer;
}

.popup-menuu p {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin: 12px 0;
  line-height: 25px;
}

.signup-div,
.login-div {
  position: absolute;
  top: 0;
  transition: all 0.5s ease-in-out;
  height: 100vh;
  transition: transform 0.5s ease-in-out, display 0s linear 0.5s;
}

.signup-div.active,
.login-div.active {
  transition: transform 0.5s ease-in-out;
}

.signup-div {
  transform: translateX(-100%);
  width: 50%;
}

.signup-div.active {
  transform: translateX(0%);
  display: block;
}

.login-div {
  transform: translateX(100%);
  width: 50%;
}

.login-div.active {
  transform: translateX(0%);
  display: block;
}

.signin-up-div {
  height: 100vh;
  overflow: hidden;
  width: 100%;
}

.pattern-bg {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23fbcb04' fill-opacity='0.05'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
}

.pattern-bg2 {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23fbcb04' fill-opacity='0.05'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
}

.pattern-bg3 {
  background-color: #f9f8fb;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23101c4c' fill-opacity='0.03'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
}

.custom-btn {
  height: 40px;
  color: var(--text) !important;
  border-radius: 4px;
  padding: 7px 16px;
  font-size: 14px;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  /* box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1); */
  outline: none;
}

.main-button {
  background: linear-gradient(to right, #bc833bdb, #fcdb6a, #e3b757) !important;
  border: none;
  z-index: 1;
  color: #563a2d !important;
  border-radius: 4px;
  font-size: 18px;
  white-space: nowrap;
}
.main-button:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: var(--white-bg);
  border-radius: 4px;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 4px var(--text-primary);
  color: var(--text-primary) !important;
}
.main-button:hover {
  color: var(--text-pent) !important;
}
.main-button:hover:after {
  left: 0;
  width: 100%;
}
.main-button:active {
  top: 2px;
}

.home-page {
  min-height: 100vh;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  background: url(./Assets/Media/Backgrounds/pexels-codioful-7134986.jpg);
  background-size: cover;
}

.mockbrowser-box {
  background: url(./Assets/Media/Backgrounds/pexels-codioful-7130469.jpg);
  background-size: cover;
  outline: 1px solid white;
}

.mockbrowser-Outer {
  background: linear-gradient(
    to top,

    #abeff6,
    #d2f8fb,
    #72e2ee,
    #30cadf,
    #148ba6,
    #177087
  ) !important;
  padding: 6px;
  border-radius: 18px;
  box-shadow: 1px -1px 20px 0px #228893;
  outline: 2px solid white;
  border: 2px solid #148ba6;
  margin-top: 120px;
}

.ad-brand {
  background: url(./Assets/Media/Backgrounds/pexels-codioful-7130469.jpg);
  background-size: cover;
  width: fit-content;
  padding: 0px 8px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  border: 1px solid #177087;
}

.ad-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.timer-time-box {
  background: url(./Assets/Media/Backgrounds/pexels-codioful-7130469.jpg);
  background-size: cover;
  border: 2px solid white;
  outline: 1px solid #177087;
  box-shadow: 0px 0px 4px #177087;
}

.timer-time {
  color: #177087;
  font-weight: 800;
  font-family: monospace;
}
