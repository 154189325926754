.reveal-button {
  place-self: center;
  overflow: hidden;
  position: relative;
  border: none;
  background: #000 !important;
  text-transform: uppercase;
  cursor: pointer;
  color: hsl(48, 100%, calc((1 + var(--j, 0)) * 50%));
}

.Topbarmain .Login-button {
  color: white;
  border: 1px solid white;
  padding: 5px 1rem 5px 1rem;
  letter-spacing: 1px;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 600;
}

.Login-button {
  color: white;
  border: 1px solid white;
  padding: 5px 1rem 5px 1rem;
  letter-spacing: 1px;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 600;
}

.reveal-button::before,
.reveal-button::after {
  --i: 0;
  --j: calc(1 - var(--i));
  position: absolute;
  z-index: 2;
  inset: -1px;
  translate: calc((var(--hl, 0) - var(--j)) * 100%);
  background: currentcolor;
  mix-blend-mode: saturation;
  transition: translate var(--t, 0.35s);
  content: "";
  color: hsl(48, 100%, calc((1 + var(--j, 0)) * 50%));
}

.reveal-button::after {
  --i: 1;
  mix-blend-mode: difference;
}

.reveal-button:hover,
.reveal-button:focus {
  --hl: 1;
}

.slow-mo {
  --t: 1.05s;
}

@keyframes pulse2 {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }
  70% {
    transform: scale(1.02);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

.pulse-animation {
  animation: pulse2 5s infinite;
  border-radius: 8px;
}

@keyframes wave2 {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes wave3 {
  0% {
    background-position: 0% 50%;
    background-size: 200%;
    opacity: 1;
  }
  25% {
    background-position: 50% 50%;
    background-size: 220%;
    opacity: 0.8;
  }
  50% {
    background-position: 100% 50%;
    background-size: 200%;
    opacity: 1;
  }
  75% {
    background-position: 50% 50%;
    background-size: 220%;
    opacity: 0.8;
  }
  100% {
    background-position: 0% 50%;
    background-size: 200%;
    opacity: 1;
  }
}

.wave-animation {
  background: linear-gradient(
    270deg,
    var(--text-pent),
    #fbcb04,
    var(--text-pent)
  );
  background-size: 400% 400%;
  animation: wave3 2s ease infinite;
  transition: transform 0.3s; /* Smooth transform transition */
  color: #563a2d !important;
  font-weight: 600 !important;
}

.wave-animation:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.timer-btn {
  background: linear-gradient(
    to bottom,
    #3b0764,
    #581c87,
    #6b21a8,
    #7e22ce,
    #9333ea,
    #a855f7,
    #ec4899,
    #db2777,
    #be185d,
    #9d174d,
    #831843,
    #500724
  );
  background: url(./../Media/Backgrounds/pexels-codioful-7130469.jpg);
  color: #581c87 !important;
  background-size: cover;
  padding: 1px 16px !important;
  height: unset !important;
  line-height: unset !important;
  min-height: unset !important;
  height: 40px !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  border: 1px solid #6b21a8 !important;
  box-shadow: 0px 0px 2px;
  text-transform: uppercase !important;
}

.timer-btn:hover {
  box-shadow: 0px 0px 4px;
  transform: scale(1.01);
  transition: all 200ms ease-in-out;
}

/* Comment added */
