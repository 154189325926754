/* -------------********************************---------------- 
                          MAIN STYLING
 --------------- ********************************---------------- */

/* -------------********************************---------------- 
        Styling for responsiveness can be found at the end of the document 
-------------********************************----------------  */

// ******** ------------ Google Fonts ------------- *******

@import url("https://fonts.googleapis.com/css?family=Droid+Sans");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400");
@import url("https://fonts.googleapis.com/css?family=Metropolis:700");
@import url("https://fonts.googleapis.com/css?family=Raleway:300");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600");
@import url("https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");
@import url("https://fonts.googleapis.com/css?family=Shadows+Into+Light");
@import "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap";

// ******** ------------ External Fonts ------------- *******

body::selection {
  background: #fbcb04;
  color: black;
}

.input-section {
  font-family: "Open Sans", sans-serif;
  border: 1px solid black !important;
  border-radius: 4px !important;
  padding: 8px 10px;
  width: 100%;
  margin-top: 12px;
}

.cookie-consent {
  font-family: "Open Sans", sans-serif !important;
}

.custom-cookie-style {
  margin: 20px;
}

.accordion_header {
  position: relative;
  border-bottom: 0px !important;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

.pricing-nav {
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
}

.checkout-part {
  padding-top: 120px;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
  padding-bottom: 50px;
}

input {
  outline: none !important;
}

input:focus {
  outline: none !important;
  border-color: #719ece;
  box-shadow: 0 0 10px #719ece;
}

.contact-part {
  background-attachment: fixed;
  padding: 20px 59px;
  padding-top: 40px;
}

.section-center {
  // background-image: url(./components/Hero/Media/Two2.png);
  padding: 120px 50px 50px;
}

.contact-info {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}

.contact-info i {
  background: linear-gradient(#359eff, #067ef1);
  border-radius: 50%;
  box-shadow: 0 25px 35px 0 rgb(0 0 0 / 15%);
  color: #fff;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  margin-right: 25px;
  text-align: center;
  text-shadow: 2px 3px 8px rgb(0 0 0 / 10%);
  width: 50px;
}

.CallSchedule {
  width: 80%;
  margin-left: 10%;
}

.schedulestyl {
  // background: #ffffff !important;
  // background-image: url(./../src/components/Hero/Media/Two.png);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
  padding-bottom: 30px;
}

.input-section:focus {
  outline: 0px solid !important;
  border: 1px solid black !important;
}

.title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 56px;
  font-weight: 800;
  text-align: center;
  font-style: normal;
  letter-spacing: -0.03em;
  position: relative;
  z-index: 1;
  padding: 10px 20px;
  margin-left: 20px;
}

.title1 {
  font-family: "Open Sans", sans-serif;
  font-size: 40px;
  line-height: 56px;
  font-weight: 800;
  font-style: normal;
  letter-spacing: -0.03em;
  color: black;

  position: relative;
  z-index: 1;
  padding: 10px 20px;
  margin-left: 20px;
}

.title-gold {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 40px;
  text-align: center;
  line-height: 56px;
  font-weight: 800;
  font-style: normal;
  letter-spacing: -0.03em;
  color: #fbcb04 !important;
  position: relative;
  z-index: 1;
  padding: 10px 20px;
  margin-left: 20px;
}

.title:before {
  margin-bottom: 15px;
  content: "";
  position: absolute;
  /* left: 25px; */
  top: 0;
  height: 5px;
  width: 10%;
  // border-top: 5px solid #fbcb04;
  border-radius: 50px;
}

.title-sub {
  font-family: "Open Sans", sans-serif;
  width: 90%;
  font-size: 24px;
  line-height: 40px;
  color: white;
  margin-left: 35px;
  text-transform: none;
  text-align: center;
  padding: 0px 5px;
  font-weight: 400;
  font-style: normal;
}

.title-sub::selection {
  background: #fbcb04;
  color: black;
}

.heading {
  color: white;
  font-size: 1.45rem;
  line-height: 150%;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.heading-white {
  color: white !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 20px !important;
  font-weight: bold !important;
  line-height: 28 px !important;
}

.heading-black {
  color: black;
  font-size: 1.45rem;
  line-height: 150%;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  margin: 20px 0px 10px;
}

.heading-small {
  font-family: "Open Sans", sans-serif !important;
  font-size: 20px !important;
  font-weight: bold !important;
  line-height: 28 px !important;
  color: white;
}

.heading-gold {
  font-family: "Open Sans", sans-serif !important;
  font-size: 25px !important;
  font-weight: bold !important;
  line-height: 28 px !important;
  color: #fbcb04 !important;
}

.paragraph {
  font-family: "Open Sans", sans-serif !important;
  font-size: 1rem !important;
  line-height: 160% !important;
  margin-top: 5px;
  color: black !important;
}

.heading::selection {
  background-color: #fbcb04;
}

.paragraph::selection {
  background-color: #fbcb04;
}

.paragraph1 {
  font-family: "Optima";
  font-size: 18px;
  opacity: 100%;
  // font-weight: 600;
}

.paragraph1:hover {
  color: #fbcb04;
}

.paragraph-white {
  font-family: "Open Sans", sans-serif !important;
  font-size: 15px;
  line-height: 22px;
  margin-top: 5px;
  color: white !important;
  font-size: 1.05rem;
  line-height: 160%;
  margin-top: 5px;
  opacity: 80%;
}

.custom-btn {
  // width: 130px; */
  height: 40px;
  color: black !important;
  border-radius: 5px;
  padding: 7px 25px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  // box-shadow: inset 2px 2px 2px 0px rgb(255 255 255 / 50%),
  //   7px 7px 20px 0px rgb(0 0 0 / 10%), 4px 4px 5px 0px rgb(0 0 0 / 10%);
  outline: none;
}

.main-button {
  background: linear-gradient(to right, #bc833bdb, #fcdb6a, #e3b757) !important;
  border: none;
  z-index: 1;
  color: #563a2d !important;
  border-radius: 4px;
  font-size: 18px;
  white-space: nowrap;
}

.main-button:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: white;
  border-radius: 4px;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 4px #563a2d;
  color: #563a2d !important;
}

.main-button:hover {
  color: #563a2d !important;
  transition: all 0.3s ease;
}

.main-button:hover:after {
  left: 0;
  width: 100%;
}

.main-button:active {
  top: 2px;
}

.main-button2 {
  background: linear-gradient(
    to right,
    #101c4c8e,
    #fcdb6a,
    #101c4c9c
  ) !important;
  border: none;
  z-index: 1;
  color: #563a2d !important;
  border-radius: 4px;
  font-size: 18px;
  white-space: nowrap;
}

.main-button2:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: white;
  border-radius: 4px;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 4px #563a2d;
  color: #563a2d !important;
}

.main-button2:hover {
  color: #563a2d !important;
  transition: all 0.3s ease;
}

.main-button2:hover:after {
  left: 0;
  width: 100%;
}

.main-button2:active {
  top: 2px;
}

// ----------- Filp-button ----------- //

.custom-btn1 {
  width: 130px;
  height: 40px;
  color: black;
  border-radius: 5px;
  padding: 7px 21px;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}

/* 12 */
.flip-btn {
  position: relative;
  right: 20px;
  bottom: 20px;
  border: none;
  box-shadow: none;
  width: 130px;
  height: 40px;
  line-height: 42px;
  -webkit-perspective: 230px;
  perspective: 230px;
  margin: 0px;
}

.flip-btn span {
  background: #fbcb04 !important;
  background: linear-gradient(
    0deg,
    rgba(0, 172, 238, 1) 0%,
    rgba(2, 126, 251, 1) 100%
  );
  display: block;
  position: absolute;
  width: 130px;
  height: 40px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 0;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-family: "Open Sans", sans-serif;
}

.flip-btn span:nth-child(1) {
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}

.flip-btn span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}

.flip-btn:hover span:nth-child(1) {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.flip-btn:hover span:nth-child(2) {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  color: transparent;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}

// ---------------------------    left to right button fill color -------------

.btn2 {
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  padding: 4px 16px 6px 16px;
  z-index: 20;
  background: #fbcb04 !important;
  transition: all 0.35s cubic-bezier(1, 0, 0, 1);
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  box-shadow: 0px 0px 0px 2px white;
  font-size: 18px;
}

.btn2:hover {
  color: white !important;
  background-color: transparent !important;
  box-shadow: 0px 0px 4px white;
}

.btn-color {
  color: black;
}

.btn-color::before,
.btn-color::after {
  background: #04042c !important;
  border-radius: 8px;
  color: black !important;
}

.btn2::before,
.btn2::after {
  content: "";
  position: absolute;
  z-index: -1;
  transition: all 0.8s cubic-bezier(1, 0, 0, 1);
}

.btn-l-r::after {
  height: 100%;
  width: 0;
  top: 0;
}

.btn-l-r:hover::after {
  width: 100%;
}

.btn-l-r::after {
  left: 0;
}

// ---------------------------    left to right button fill color -------------

// KK  ----------------------------------//

.paragraph-whatoffers {
  margin: 5%;
  font-size: 1rem;
  line-height: 160%;
  margin-top: 1rem;
  font-family: "Open Sans", sans-serif;
}

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: none;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  border-color: #2563eb;
}

.paragraph-whatoffers:hover {
  color: black !important;
}

// Colors for the entire application

.darkblue {
  color: #fbcb04 !important;
}

.white {
  color: white !important;
}

// Indicators CSS
.indicators {
  position: fixed;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9;
  background: #101c4c;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0px 0px 2px white;
}

.indicator {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 25px;
  justify-content: center;
  border: 2px solid #ffffff99;
}

.splider-text {
  top: 15px;
  font-weight: bold;
  left: auto;
  position: relative;
  display: flex;
  font-size: 12px;
  color: #ffeb44c4;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  font-variation-settings: "wdth" 60;
}

.indicator:hover .splider-text {
  color: #fbcb04;
}

.indicator.active .splider-text {
  left: 0px;
  font-weight: bold;
  position: relative;
  display: flex;
  font-size: 16px;
  color: #fbcb04;
  margin-top: -3px;
}

.indicator:hover {
  background-color: #555;
}

.indicator:focus {
  outline: none;
}

.indicator.active .check-icon {
  display: inline;
}

.check-icon {
  display: none;
  position: relative;
  top: -1px;
  left: -1px;
  color: #fff;
}

.indicator-inner {
  background-color: transparent;
  width: 9.5px;
  height: 9.5px;
  border-radius: 25px;
  margin: 2.5px !important;
}

.indicator.active .indicator-inner {
  background-color: #fbcb04;
}

.copy-box-home {
  background-color: #fbcb04 !important;
  font-family: "Open Sans", sans-serif;
  color: black !important;
}

// Indicators CSS ENDED

.map-table1 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.t-padding {
  padding-left: 6rem;
  padding-right: 6rem;
}
.cookies-modal {
  background: linear-gradient(0deg, #101c4c, #fbcb04);
  border: 2px solid white;
}

/* -------------********************************---------------- 
                  STYLING FOR RESPONSIVE SCREENS 
--------------- ********************************---------------- */

/* -------------********************************---------------- 
                    EXTRA LARGE SCREEN - Min 1910px 
--------------- ********************************---------------- */

@media only screen and (min-width: 1910px) {
  .contact-part {
    padding: 50px 22rem;
    padding-top: 90px;
  }

  .schedulestyl {
    padding: 50px 13rem;
    padding-top: 10px;
  }

  .t-padding {
    padding: 0px 23rem;
  }
}

@media only screen and (max-width: 900px) {
  .title {
    line-height: 35px !important;
    font-size: 32px;
    margin-left: 15px;
    padding: 0px;
    margin-bottom: 20px;
  }

  .title-sub {
    font-size: 16px;
    margin-left: 10px;
    line-height: 26px;
    width: 90%;
  }

  .title-gold {
    font-size: 29px !important;
    margin: 0px !important;
    padding: 0px;
    line-height: 37px;
  }

  .title1 {
    font-size: 28px !important;
    line-height: 35px;
  }

  .title:before {
    display: none;
  }
}

/* -------------********************************---------------- 
                   MOBILE SCREEN - Max - 767px 
--------------- ********************************---------------- */

@media only screen and (max-width: 767px) {
  .contact-info {
    flex-direction: column;
  }

  .contact-content,
  .contact-text {
    text-align: center;
  }

  .contact-part {
    padding: 26px 30px;
    padding-top: 60px;
  }

  .post-a-bid-part {
    margin-top: 60px;
  }

  .flip-btn {
    margin-top: 20px;
    margin-left: -7px;
    margin-bottom: 10px;
  }

  .contact-info i {
    margin-right: 0px;
  }

  .t-padding {
    padding: 0px 20px;
  }

  .indicators {
    display: none !important;
  }

  .hero-mobile-content {
    flex-direction: column;
    font-size: 40px;
  }

  .hero-mobile-content-typed {
    margin: auto;
  }

  .schedulestyl {
    margin-top: 30px;
  }
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}
