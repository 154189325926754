.all-users-div {
  padding: 20px;
  row-gap: 20px;
  margin: 0px;
}

.single-user-div {
  display: flex;
  gap: 4px;
  flex-direction: column;
  margin-bottom: 12px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px #b99653;
  align-items: center;
  text-align: center;
  width: 95%;
  margin-right: 5%;
  height: 100%;
}

.single-user-upper-div {
  background: linear-gradient(90deg, #cca97cdb, #fcdb6a, #e2be6d);
  color: #563a2d !important;
  padding: 10px 20px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 50%;
}

.single-user-lower-div {
  padding: 20px;
  height: 50%;
  width: 100%;
}

.grid-user-image {
  height: 120px !important;
  width: 120px !important;
  margin-top: 8px !important;
  margin-bottom: 16px !important;
  background: white;
  box-shadow: 0px 0px 30px #311e179c;
  border: 2px solid #b99653;
  outline: 3px solid white;
  object-fit: cover;
}
.full-details-btn {
  margin-top: 16px;
  margin-bottom: 16px;
}

.user-name {
  font-size: 20px;
  font-weight: 600;
  color: #563a2d;
}

.user-detail {
  font-size: 16px;
  font-weight: 600;
  color: #765032;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
}

.user-id {
  margin-bottom: -24px !important;
}

.user-email {
  margin-top: -4px !important;
}

.user-login {
  margin-bottom: 34px !important;
  margin-top: 2px !important;
}

.MuiInputBase-input-MuiOutlinedInput-input {
  height: 10px !important;
  font-family: "Open Sans", sans-serif !important;
}

.all-users-div-main {
  position: relative;
  overflow-y: scroll;
  height: calc(100vh - 70px);
}

.all-users-search-bar {
  position: sticky;
  top: 0px;
  padding: 20px 20px 16px;
  background: #f1f1f4;
  z-index: 99;
}

.user-count {
  margin-top: 0px !important;
}
