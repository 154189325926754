.dashboard-sidebar-div {
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: white;
}

/* .sidebar-background {
    background: url('./../../Hero/Media/Two4.png');
    background-size: cover;
    background-position: center;
    height: 100%;
} */

.dashboard-sidebar {
  min-width: 250px !important;
  width: fit-content !important;
  max-width: 400px !important;
  height: 100%;
  background: transparent;
}

.dashboard-header {
  position: sticky;
  top: 0px;
  z-index: 10;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 16px;
  padding-right: 20px;
  --tw-shadow: 0 0px 1px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  height: 70px;
}

.dashboard-title {
  font-size: 1.6rem;
  font-weight: 900;
  letter-spacing: 2px;
  align-items: center;
  display: flex;
}

.dashboard-logo-img {
  display: block;
  width: 100%;
  height: 50px;
  object-fit: contain;
  object-position: center;
  background-color: transparent;
}

.dashboard-header-close-btn {
  font-size: 22px;
  padding: 2px;
  box-shadow: 0px 0px 3px grey;
  border-radius: 100%;
}

.dashboard-header-profile-pic {
  position: relative;
}

.dashboard-header-profile-pic-dots-btn {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  display: none;
  flex-direction: column;
  justify-content: center;
}

.dashboard-header-profile-pic:hover .dashboard-header-profile-pic-dots-btn {
  display: flex;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 10px 1rem !important;
  font-size: 18px;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background: transparent !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  font-size: 22px !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

/* .pro-sidebar .pro-menu .pro-menu-item:hover {
  background: red;
  width: 95%;
} */

.pro-sidebar .pro-menu .pro-menu-item.active {
  color: black !important;
  background: #fbcb04;
  width: fit-content;
  width: 95%;
  border-radius: 8px;
}

.pro-sidebar .pro-menu .pro-menu-item.active span {
  color: black;
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  background: #101c4c !important;
  margin-top: 75px !important;
  padding-left: 10px !important;
}

.dashboard-page {
  @apply flex flex-col gap-5 p-5 pt-[80px] md:pt-[20px]  md:px-[20px] md:pb-[40px];
  overflow: scroll;
}

.dashboard-card {
  @apply shadow-[0px_0px_4px_grey] shadow-[1px_1px_2px_grey] relative overflow-hidden z-[1] flex flex-col rounded-lg border-l-4 border-l-[#002549] border-solid;
  overflow: scroll;
  padding-bottom: 0px;
}

.dashboard-box-header {
  @apply flex justify-between p-4 border-b-[#dcdcdc] border-b border-solid;
}

.dashboard-content {
  @apply p-4;
  overflow: scroll;
  padding-bottom: 0px;
}

.dashboard-footer {
  @apply flex justify-end p-4 border-t-[#dcdcdc] border-t border-solid gap-[12px];
  padding-bottom: 0px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  min-width: 45px !important;
}

.MuiTypography-root {
  font-family: "Open Sans", sans-serif !important;
}

.dashboard-header-hamburger-button:hover {
  background: #efefef;
  border-radius: 30px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item:hover {
  background: rgba(255, 255, 255, 0.233) !important;
  color: white;
  width: 95%;
  border-radius: 8px;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item:hover
  svg {
  fill: #101c4c;
  stroke: #fbcb04;
  @apply animate-pulse;
}

.pro-sidebar .pro-menu .pro-menu-item.active svg {
  stroke: black !important;
  fill: #fbcb04 !important;
}
