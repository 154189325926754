/* -------------********************************---------------- 
                          MAIN STYLING
 --------------- ********************************---------------- */

/* -------------********************************---------------- 
        Styling for responsiveness can be found at the end of the document 
-------------********************************----------------  */

.Login-Page {
  width: 100%;
  min-height: 100vh;
}
.modalmy {
  background: linear-gradient(179deg, #fbcb04, white);
}

.Stack-box {
  background-color: transparent !important;
}

.login-flex-box {
  min-height: 100vh;
}

.login-stack-box {
  background: transparent !important;
  padding: 1rem 5rem;
  height: 100vh;
  justify-content: center;
}

.main-title {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 38px;
  margin-bottom: 30px;
}

.login-box {
  position: relative;
  margin-top: 40px;
  padding: 40px 50px;
  border-radius: 8px;
  background: url(./../../Assets/Media/Backgrounds/popupbg.png);
  border-radius: 16px;
  box-shadow: 0px 0px 4px 1px #2153ac21;
  background-size: cover;
  background-position: center;
  border: 4px solid white;
  box-shadow: 1px 0px 60px #b99653;
  outline: 4px solid #b99653;
}

.login-page-form {
  margin: 0px;
  display: flex;
}

.login-title {
  width: 100%;
}

.login-heading {
  text-align: left !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  text-transform: none !important;
  font-size: 30px;
  margin-bottom: 22px;
  color: #101c4c;
  font-weight: 500;
  font-weight: 600 !important;
  font-size: 25px !important;
  text-decoration: none !important;
  margin-top: 4px;
}

.label-text {
  margin-bottom: 10px;
  color: black !important;
}

.login-link:hover {
  color: #fbcb04;
  text-decoration: underline;
}

.signup-input {
  font-family: "Open Sans", sans-serif;
  background-color: white !important;
  border: 1px solid transparent !important;
  width: 100%;
  padding: 8px 12px;
  border-radius: 12px !important;
  margin-bottom: 8px;
  color: var(--text-primary);
  font-size: 16px;
  box-shadow: 0px 0px 2px grey;
}

.signup-input::placeholder {
  font-family: "Open Sans", sans-serif;
}

.signup-input:focus {
  border: 1px solid #fbcb04 !important;
  background: #fefde8 !important;
}

.signup-input1 {
  font-family: "Open Sans", sans-serif;
  margin-top: 10px;
  background-color: #fff !important;
  border: 0px solid rgba(0, 0, 0, 0.4392156863) !important;
  margin: 0px !important;
  width: 100%;
}

.green-btn {
  padding: 8px 16px;
  background-color: #2153ac;
  color: var(--text-white);
  background: linear-gradient(180deg, #101c4c, #fbcb04) !important;
  cursor: pointer;
  font-size: 16px;
  border-radius: 16px;
}

.forgot-password-link {
  text-decoration: none;
  margin: 20px 5px 0px;
  font-family: "Open Sans", sans-serif;
}

.main-button1 {
  font-family: "Open Sans", sans-serif !important;
  background-color: #044b61 !important;
  color: white !important;
  padding: 8px 15px;
  border-radius: 16px;
}

.signup-input1:focus {
  border: 0px solid rgba(0, 0, 0, 0.4392156863) !important;
}

.login-password-show-btn {
  height: 37px;
  width: 10%;
  display: flex;
  justify-content: center;
  padding-top: 8px;
  border-radius: 0px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 22px;
}

.login-password-show-btn:hover {
  display: block;
  height: 37px;
  width: 10%;
  display: flex;
  justify-content: center;
  padding-top: 8px;
  border-radius: 0px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 22px;
  color: #000 !important;
}

.login-btn {
  background-color: #fbcb04 !important;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.login-btn:hover {
  color: #000 !important;
}

.wrapper-login {
  width: 25%;
  padding: 20px;
  background-color: #fff;
}

.title-login {
  font-size: 24px;
  font-weight: 300;
}

form {
  flex-direction: column;
}

.input-login {
  flex: 1;
  min-width: 40%;
  margin: 10px 0px;
  padding: 10px;
}

.btn-crear {
  width: 40%;
  border: none;
  padding: 15px 20px;
  background-color: teal;
  color: #fff;
  cursor: pointer;
  margin-bottom: 10px;
}

.password {
  margin: 5px 0px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  color: #000;
}

.loading-icon-threedots {
  display: block !important;
  justify-content: center;
}

.password-input-div {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.4392156863);
  border-radius: 5px;
  margin-bottom: 30px;
}

.blur {
  filter: blur(5px);
}

.blur-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.login-left {
  background: url(./../../Assets/Media/Backgrounds/Two4.png);
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  z-index: 9;
  font-family: "Open Sans", sans-serif;
}

.login-right {
  padding: 0px;
}

.MuiInputBase-input-MuiOutlinedInput-input {
  font-family: "Open Sans", sans-serif !important;
}

.MuiInputBase-input-MuiOutlinedInput-input::placeholder {
  font-family: "Open Sans", sans-serif !important;
}

.password-div-field {
  position: relative;
}

.info-icon {
  display: block;
  position: absolute;
  left: -30px;
  top: 16px;
  width: 20px;
  height: 20px;
  color: #2153ac;
  font-size: 24px;
}

.custom-tooltip {
  font-family: "Open Sans", sans-serif !important;
  color: white !important;
  max-width: none !important;
  width: auto !important;
  padding: 0px;
  background: linear-gradient(180deg, #101c4c, #fbcb04) !important;
  /* Adjust as needed */
}

.MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Open Sans", sans-serif !important;
}

.MuiOutlinedInput-root {
  font-family: "Open Sans", sans-serif !important;
}

.MuiInputBase-input-MuiOutlinedInput-input {
  font-family: "Open Sans", sans-serif !important;
}

.MuiFormLabel-root-MuiInputLabel-root {
  font-family: "Open Sans", sans-serif !important;
}

.MuiInputLabel-root {
  font-family: "Open Sans", sans-serif !important;
}

.MuiFormHelperText-root {
  font-family: "Open Sans", sans-serif !important;
}

.MuiInputBase-input-MuiOutlinedInput-input {
}

.MuiOutlinedInput-input {
  padding: 15px 12px !important;
  height: 10px !important;
}

/* -------------********************************---------------- 
                  STYLING FOR RESPONSIVE SCREENS 
--------------- ********************************---------------- */

/* -------------********************************---------------- 
                   MOBILE SCREEN - Min - 1910px 
--------------- ********************************---------------- */

@media only screen and (min-width: 1910px) {
  .login-left {
    padding-left: 22rem;
  }

  .login-right {
    padding-right: 22rem;
  }
}

/* -------------********************************---------------- 
                   MOBILE SCREEN - Max - 767px 
--------------- ********************************---------------- */

@media only screen and (max-width: 767px) {
  .login-box {
    width: 100%;
    padding: 30px;
    margin-top: 0px;
  }

  .Stack-box {
    width: 90% !important;
    margin-top: 120px !important;
  }

  .signup-link {
    width: 33%;
  }

  .signup-link1 {
    width: 55%;
  }

  .login-stack-box {
    width: 100%;
    margin-top: 50px !important;
    padding: 1rem;
  }

  .login-title {
    width: 310px;
  }
}

@media only screen and (max-width: 767px) {
  .login-left {
    min-height: 80vh;
  }

  .login-right {
    min-height: fit-content;
    /* padding: 40px 0px; */
  }
}
