.single-admin-upper-div {
  background: url(./../../../Assets/Media/Backgrounds/CardBackgrounds/cardbgbluegold.jpg);
  background: url(./../../../Assets/Media/Backgrounds/AdminSidebarBG2.png);
  background: url(./../../../Assets//Media/Backgrounds/CardBackgrounds/cardbg3.jpg);
  background-size: inherit;
  filter: drop-shadow(2px 4px 6px black);
  padding: 10px 20px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 50%;
  background-position: right;
}

.admin-name {
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.admin-id {
  color: white !important;
}

.admin-detail {
  font-size: 16px;
  font-weight: 600;
  color: #101c4c;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
}

.single-admin-div {
  display: flex;
  gap: 4px;
  flex-direction: column;
  margin-bottom: 12px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px #101c4c;
  align-items: center;
  text-align: center;
  width: 95%;
  margin-right: 5%;
  height: 100%;
}

.admin-details-input-div {
  margin-bottom: 16px;
}
.admin-details-input-div div {
  width: 100%;
}
